<template>
  <div class="px-4 py-4 ">
    <div class="tab">
      <button
        class="tabLinks cursor-pointer active font-medium w-auto"
        @click="e => openTab(e, 'dadosCadastrais')"
      >
        Dados Cadastrais
      </button>
    </div>

    <div id="dadosCadastrais" class="tabContent active">
      <vs-row class=" flex md:flex-wrap md:justify-between justify-end">
        <vs-button
          v-if="policy.ArchivePolicyId > 0"
          @click="downloadApoliceClick(policy.ProposalUniqueId)"
          color="primary"
        >
          <span class="font-semibold pr-2 pl-2">
            Download da apólice
            {{ policy.OriginalProposal ? "(original)" : "" }}
          </span>
        </vs-button>

        <vs-button
          v-if="policy.HasInstallment"
          @click="parcelasModal = true"
          color="primary"
        >
          <span class="font-semibold pr-2 pl-2">
            Download do(s) boleto(s)
          </span>
        </vs-button>
      </vs-row>

      <vs-row v-if="policy.PolicyNumber || policy.OriginalProposal">
        <vs-col
          class="container-element pt-8"
          vs-sm="12"
          vs-lg="6"
          v-if="policy.PolicyNumber"
        >
          <p class="label-content label">Número da apólice</p>
          <p class="value-content">{{ policy.PolicyNumber }}</p>
        </vs-col>
        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="6">
          <div v-if="policy.OriginalProposal && policy.OriginalProposal.Number">
            <p class="label-content label">Número da proposta</p>
            <p class="value-content" @click="routeToProposal()">
              {{ policy.OriginalProposal.Number }}
            </p>
          </div>
        </vs-col>
        <vs-col
          class="container-element pt-8"
          vs-sm="12"
          vs-lg="6"
          v-if="policy.OriginalProposal && policy.Number"
        >
          <p class="label-content label">Número da porposta original</p>
          <p class="value-content">{{ policy.Number }}</p>
        </vs-col>
      </vs-row>

      <vs-row>
        <h5 class="title-group">Dados do Tomador</h5>

        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="8">
          <p class="label-content label">Razão Social do Tomador</p>
          <p class="value-content">{{ policy.PolicyHolderName }}</p>
        </vs-col>

        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="4">
          <p class="label-content label">CNPJ do Tomador</p>
          <p class="value-content">
            {{ policy.PolicyHolderDocument | maskCnpj }}
          </p>
        </vs-col>

        <vs-col class="container-element pt-8" vs-w="12">
          <p class="label-content label">Endereço do Tomador</p>
          <p class="value-content">{{ policy.PolicyHolderAddress }}</p>
        </vs-col>
      </vs-row>

      <vs-row
        v-if="
          policy.PolicyHolderBranchName &&
            policy.PolicyHolderBranchAddress &&
            policy.PolicyHolderBranchDocument
        "
      >
        <h5 class="title-group">Dados do Tomador Filial</h5>
        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="8">
          <p class="label-content label">Razão Social do Tomador Filial</p>
          <p class="value-content">{{ policy.PolicyHolderBranchName }}</p>
        </vs-col>
        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="4">
          <p class="label-content label">CNPJ do Tomador Filial</p>
          <p class="value-content">
            {{ policy.PolicyHolderBranchDocument | maskCnpj }}
          </p>
        </vs-col>
        <vs-col class="container-element pt-8" vs-w="12">
          <p class="label-content label">Endereço do Tomador Filial</p>
          <p class="value-content">{{ policy.PolicyHolderBranchAddress }}</p>
        </vs-col>
      </vs-row>

      <vs-row>
        <h5 class="title-group">Dados do Segurado</h5>

        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="8">
          <p class="label-content label">Razão Social do Segurado</p>
          <p class="value-content">{{ policy.InsuredName }}</p>
        </vs-col>

        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="4">
          <p class="label-content label">CNPJ do Segurado</p>
          <p class="value-content">{{ policy.InsuredDocument | maskCnpj }}</p>
        </vs-col>

        <vs-col class="container-element pt-8" vs-w="12">
          <p class="label-content label">Endereço do Segurado</p>
          <p class="value-content">{{ policy.InsuredAddress }}</p>
        </vs-col>
      </vs-row>

      <vs-row>
        <h5 class="title-group">Dados do Corretor</h5>

        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="8">
          <p class="label-content label">Razão Social do Corretor</p>
          <p class="value-content">{{ policy.BrokerName }}</p>
        </vs-col>

        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="4">
          <p class="label-content label">CNPJ do Corretor</p>
          <p class="value-content">{{ policy.BrokerDocument | maskCnpj }}</p>
        </vs-col>
      </vs-row>

      <vs-row>
        <h5 class="title-group my-4">Dados da Apólice</h5>
        <h5 class="title-group mt-4">Modalidades</h5>
        <vs-col
          vs-sm="12"
          :vs-lg="!!policy.ComplementaryModalityId ? 6 : 12"
          class="px-0 py-0"
        >
          <vs-row class="px-0 py-0">
            <vs-col
              class="container-element pt-8"
              vs-sm="12"
              :vs-lg="!policy.ComplementaryModalityId ? 6 : 12"
            >
              <p class="label-content label">Modalidade Principal</p>
              <p class="value-content">
                {{ policy.Modality }}
              </p>
            </vs-col>

            <vs-col
              class="container-element pt-8"
              vs-sm="12"
              :vs-lg="!policy.ComplementaryModalityId ? 3 : 6"
            >
              <p class="label-content label">Importância Segurada Principal</p>
              <p class="value-content">
                {{ (policy || {}).InsuredAmountValue | maskCurrency }}
              </p>
            </vs-col>
            <vs-col
              class="container-element pt-8"
              vs-sm="12"
              :vs-lg="!policy.ComplementaryModalityId ? 3 : 6"
            >
              <p class="label-content label">Prêmio</p>
              <p class="value-content">
                {{ (policy || {}).InsurancePremiumPrincipalModality | maskCurrency }}
              </p>
            </vs-col>
          </vs-row>
          <vs-row class="px-0 py-0">
            <vs-col class="container-element pt-10" vs-sm="12" vs-lg="4">
              <p class="label-content label">Início da Vigência Principal</p>
              <p class="value-content">
                {{ $utils.dateToDDMMYYYY(policy.StartDate) }}
              </p>
            </vs-col>

            <vs-col class="container-element pt-10" vs-sm="12" vs-lg="4">
              <p class="label-content label">Prazo da Vigência Principal</p>
              <p class="value-content">
                {{ policy.DeadlineDays }}
              </p>
            </vs-col>

            <vs-col class="container-element pt-10" vs-sm="12" vs-lg="4">
              <p class="label-content label">Fim da Vigência Principal</p>
              <p class="value-content">
                {{ $utils.dateToDDMMYYYY(policy.EndDate) }}
              </p>
            </vs-col>
          </vs-row>
        </vs-col>

        <vs-col
          v-if="!!policy.ComplementaryModalityId"
          vs-sm="12"
          vs-lg="6"
          class="px-0 py-0"
        >
          <vs-row class="px-0 py-0">
            <vs-col class="container-element pt-8" vs-sm="12" vs-lg="12">
              <p class="label-content label">Modalidade Secundária</p>
              <p class="value-content">
                {{ policy.ComplementaryModality }}
              </p>
            </vs-col>


            <vs-col class="container-element pt-8" vs-sm="6" vs-lg="6">
              <p class="label-content label">Importância Segurada Secundária</p>
              <p class="value-content">
                {{
                  (policy || {}).ComplementaryInsuredAmountValue | maskCurrency
                }}
              </p>
            </vs-col>
            <vs-col class="container-element pt-8" vs-sm="6" vs-lg="6">
              <p class="label-content label">Prêmio</p>
              <p class="value-content">
                {{
                 (policy || {}).InsurancePremiumComplementaryModality | maskCurrency
                }}
              </p>
            </vs-col>

            <vs-col class="container-element pt-10" vs-sm="12" vs-lg="4">
              <p class="label-content label">Início da Vigência Secundária</p>
              <p class="value-content">
                {{
                  $utils.dateToDDMMYYYY(policy.ComplementaryModalityStartDate)
                }}
              </p>
            </vs-col>

            <vs-col class="container-element pt-10" vs-sm="12" vs-lg="4">
              <p class="label-content label">Prazo da Vigência Secundária</p>
              <p class="value-content">
                {{ policy.ComplementaryDeadlineDays }}
              </p>
            </vs-col>

            <vs-col class="container-element pt-10" vs-sm="12" vs-lg="4">
              <p class="label-content label">Fim da Vigência Secundária</p>
              <p class="value-content">
                {{ $utils.dateToDDMMYYYY(policy.ComplementaryModalityEndDate) }}
              </p>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-row v-if="(policy.Coverages || []).length">
          <h5 class="title-group mt-4 mb-4">Coberturas Adicionais</h5>
          <CoberturaColumn vs-sm="12" :collumnWidth="4" label="Nome da cobertura" :values="policy.Coverages.map(c => c.Name)" />
          <CoberturaColumn vs-sm="12" :collumnWidth="2" label="Prêmio" :values="policy.Coverages.map(c => $options.filters.maskCurrency(c.Value))" />
          <CoberturaColumn label="Início da vigência" :collumnWidth="3" :values="policy.Coverages.map(c => $utils.dateToDDMMYYYY(c.StartDate))" />
          <CoberturaColumn label="Fim da vigência" :collumnWidth="3" :values="policy.Coverages.map(c => $utils.dateToDDMMYYYY(c.EndDate))" />
        </vs-row>
      </vs-row>

      <vs-row v-if="(parcelas || []).length">
        <h5 class="title-group mt-4">Prêmio</h5>

        <vs-col
          class="container-element pt-8"
          vs-sm="6"
          vs-lg="3"
          v-if="policy.InsurancePremium"
        >
          <p class="label-content label">Prêmio Total</p>
          <p class="value-content">
            {{ policy.InsurancePremium | maskCurrency }}
          </p>
        </vs-col>
        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="3">
          <p class="label-content label">Número de parcelas</p>
          <p class="value-content">{{ (parcelas || []).length }}</p>
        </vs-col>

        <vs-col class="container-element pt-8" vs-sm="12" vs-lg="3">
          <p class="label-content label">Parcelas</p>
          <p
            class="value-content"
            :class="{ 'mt-4': index > 0 }"
            :key="index"
            v-for="(parcela, index) in parcelas"
          >
            {{ parcela.Description }}
          </p>
        </vs-col>
      </vs-row>

      <vs-row>
        <h5 class="title-group pb-2">Configuração</h5>

        <vs-col vs-sm="12" vs-lg="4">
          <vs-checkbox :disabled="true" v-model="policy.EnableToSteppin"
            >Retoma de objeto (Step-in)
          </vs-checkbox>
        </vs-col>

        <vs-col vs-sm="12" vs-lg="4">
          <vs-checkbox :disabled="true" v-model="policy.EnableToMonitoring"
            >Monitoramento
          </vs-checkbox>
        </vs-col>

        <vs-col vs-sm="12" vs-lg="4">
          <vs-checkbox :disabled="true" v-model="policy.EnableToBigRisk"
            >Grande risco
          </vs-checkbox>
        </vs-col>
      </vs-row>

      <listar-parcelas-popup
        :parcelasModal="parcelasModal"
        :propostaUniqueId="policy.ProposalUniqueId"
        @fecharParcelasModal="parcelasModal = false"
        @setParcelas="parcelas = $event"
        :autoLoad="true"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { mask, TheMask } from "vue-the-mask";
import ListarParcelasPopup from "@/views/propostas/components/visualizacao/ListarParcelasPopup.vue";
import { maskCnpj } from "../../../utils/utils";
import perfilHelpers from "@/helpers/PerfilHelpers";
import CoberturaColumn from "./CoberturaColumn.vue";
export default {
  name: "detalhar-apolice",
  components: {
    TheMask,
    ListarParcelasPopup,
    CoberturaColumn
  },
  directives: { mask },
  props: {
    policy: { type: Object, required: true }
  },
  data() {
    return {
      PerfilHelpers: perfilHelpers,
      endossoModal: false,
      parcelasModal: false,
      parcelas: null
    };
  },
  methods: {
    ...mapActions("endosso-module", ["getListEndorsementPolicy"]),

    ...mapActions("apolice-module", [
      "downloadApolice",
      "disableAndEnableRenovacao",
      "downloadUltimoEndosso"
    ]),

    async loadEndorsementList() {
      await this.$onpoint.loading(async() => {
        return await this.getListEndorsementPolicy(this.policy.PolicyUniqueId);
      });
    },

    openTab(event, tabName) {
      [...document.getElementsByClassName("tabContent")].forEach(content => {
        content.classList.remove("active");
      });
      [...document.getElementsByClassName("tabLinks")].forEach(link => {
        link.classList.remove("active");
      });
      document.getElementById(tabName).classList.add("active");
      event.currentTarget.classList.add("active");
    },

    downloadApoliceClick(propostaUniqueId) {
      let payload = {
        propostaUniqueId: propostaUniqueId,
        endorsement: !this.policy.OriginalProposal
      };

      this.$onpoint.loading(() => {
        return this.downloadApolice(payload)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    async disclamerdesabilitarRenovacao(data) {
      await this.$confirm(
        "Tem certeza que deseja realizar essa  ação?",
        "Atenção!",
        {
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar"
        }
      )
        .then(async () => {
          await this.desabilitarRenovacao(data);
        })
        .catch(() => {
          this.policy.IsBlockRenewal = !this.policy.IsBlockRenewal;
        });
    },
    async desabilitarRenovacao(data) {
      let payload = {
        PolicyUniqueId: data.PolicyUniqueId,
        IsBlockRenewal: data.IsBlockRenewal
      };
      await this.$onpoint.loading(async () => {
        return await this.disableAndEnableRenovacao(payload)
          .then(result => {
            this.$notify({
              title: "Success",
              message: "Dados atualizados com sucesso!",
              type: "success"
            });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    downloadUltimoEndossoClick(propostaUniqueId) {
      this.$onpoint.loading(() => {
        return this.downloadUltimoEndosso(propostaUniqueId)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    routeToProposal() {
      const profilePermited = ["RiskAdviser", "Root"];
      const profileUser = this.userInfo.ProfileName;
      return profilePermited.includes(profileUser)
        ? this.$router.push(
            `/propostas-seguradora/proposta/Proposta/${this.policy.ProposalUniqueId}`
          )
        : null;
    }
  },
  computed: {
    ...mapGetters("auth", ["userInfo"])
  },
  async mounted() {
    await this.loadEndorsementList();
  }
};
</script>

<style lang="scss">
.tab {
  overflow: hidden;
  background-color: white;
}
/* Style the buttons inside the tab */
.tab button {
  background-color: white;
  float: left;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  padding: 10px 14px;
  transition: 0.3s;
  font-size: inherit;
  color: #848484;
  border-radius: 0 !important;
  font-family: inherit;
}
/* Create an active/current tablink class */
.tab button.active {
  color: rgba(var(--vs-warning), 1);
  border-bottom: 2px solid rgba(var(--vs-warning), 1);
}
/* Style the tab content */
.tabContent {
  display: none;
  padding: 6px 12px;
  border: none;
  border-top: 2px solid #d9d9d9;
  margin-top: -2px;
}

.tabContent.active {
  display: block;
}

#dadosCadastrais {
  .container-element {
    position: relative;

    .label-content {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 5px;
    }
    .label {
      transform: translateY(-95%) scale(0.9);
      transform-origin: top-left;
      position: absolute;
    }

    p.value-content {
      background: #f6f6f6;
      border-radius: 50px;
      padding: 10px 15px;
      min-height: 41px;
    }
  }

  h5.title-group {
    font-size: 18px;
    color: #a1a1a1;
    font-weight: 500;
    width: 100%;
    padding: 10px 0.5rem 0;
  }
}
</style>
