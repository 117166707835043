var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-4 py-4" }, [
    _c("div", { staticClass: "tab" }, [
      _c(
        "button",
        {
          staticClass: "tabLinks cursor-pointer active font-medium w-auto",
          on: { click: (e) => _vm.openTab(e, "dadosCadastrais") },
        },
        [_vm._v("\n      Dados Cadastrais\n    ")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "tabContent active", attrs: { id: "dadosCadastrais" } },
      [
        _c(
          "vs-row",
          { staticClass: "flex md:flex-wrap md:justify-between justify-end" },
          [
            _vm.policy.ArchivePolicyId > 0
              ? _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadApoliceClick(
                          _vm.policy.ProposalUniqueId
                        )
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v(
                        "\n          Download da apólice\n          " +
                          _vm._s(
                            _vm.policy.OriginalProposal ? "(original)" : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.policy.HasInstallment
              ? _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.parcelasModal = true
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n          Download do(s) boleto(s)\n        "),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.policy.PolicyNumber || _vm.policy.OriginalProposal
          ? _c(
              "vs-row",
              [
                _vm.policy.PolicyNumber
                  ? _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-8",
                        attrs: { "vs-sm": "12", "vs-lg": "6" },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Número da apólice"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(_vm._s(_vm.policy.PolicyNumber)),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "vs-col",
                  {
                    staticClass: "container-element pt-8",
                    attrs: { "vs-sm": "12", "vs-lg": "6" },
                  },
                  [
                    _vm.policy.OriginalProposal &&
                    _vm.policy.OriginalProposal.Number
                      ? _c("div", [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Número da proposta"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "value-content",
                              on: {
                                click: function ($event) {
                                  return _vm.routeToProposal()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.policy.OriginalProposal.Number) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.policy.OriginalProposal && _vm.policy.Number
                  ? _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-8",
                        attrs: { "vs-sm": "12", "vs-lg": "6" },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Número da porposta original"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(_vm._s(_vm.policy.Number)),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c(
          "vs-row",
          [
            _c("h5", { staticClass: "title-group" }, [
              _vm._v("Dados do Tomador"),
            ]),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-sm": "12", "vs-lg": "8" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("Razão Social do Tomador"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(_vm._s(_vm.policy.PolicyHolderName)),
                ]),
              ]
            ),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-sm": "12", "vs-lg": "4" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("CNPJ do Tomador"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("maskCnpj")(_vm.policy.PolicyHolderDocument)
                      ) +
                      "\n        "
                  ),
                ]),
              ]
            ),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-w": "12" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("Endereço do Tomador"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(_vm._s(_vm.policy.PolicyHolderAddress)),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm.policy.PolicyHolderBranchName &&
        _vm.policy.PolicyHolderBranchAddress &&
        _vm.policy.PolicyHolderBranchDocument
          ? _c(
              "vs-row",
              [
                _c("h5", { staticClass: "title-group" }, [
                  _vm._v("Dados do Tomador Filial"),
                ]),
                _c(
                  "vs-col",
                  {
                    staticClass: "container-element pt-8",
                    attrs: { "vs-sm": "12", "vs-lg": "8" },
                  },
                  [
                    _c("p", { staticClass: "label-content label" }, [
                      _vm._v("Razão Social do Tomador Filial"),
                    ]),
                    _c("p", { staticClass: "value-content" }, [
                      _vm._v(_vm._s(_vm.policy.PolicyHolderBranchName)),
                    ]),
                  ]
                ),
                _c(
                  "vs-col",
                  {
                    staticClass: "container-element pt-8",
                    attrs: { "vs-sm": "12", "vs-lg": "4" },
                  },
                  [
                    _c("p", { staticClass: "label-content label" }, [
                      _vm._v("CNPJ do Tomador Filial"),
                    ]),
                    _c("p", { staticClass: "value-content" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("maskCnpj")(
                              _vm.policy.PolicyHolderBranchDocument
                            )
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "vs-col",
                  {
                    staticClass: "container-element pt-8",
                    attrs: { "vs-w": "12" },
                  },
                  [
                    _c("p", { staticClass: "label-content label" }, [
                      _vm._v("Endereço do Tomador Filial"),
                    ]),
                    _c("p", { staticClass: "value-content" }, [
                      _vm._v(_vm._s(_vm.policy.PolicyHolderBranchAddress)),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "vs-row",
          [
            _c("h5", { staticClass: "title-group" }, [
              _vm._v("Dados do Segurado"),
            ]),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-sm": "12", "vs-lg": "8" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("Razão Social do Segurado"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(_vm._s(_vm.policy.InsuredName)),
                ]),
              ]
            ),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-sm": "12", "vs-lg": "4" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("CNPJ do Segurado"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(
                    _vm._s(_vm._f("maskCnpj")(_vm.policy.InsuredDocument))
                  ),
                ]),
              ]
            ),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-w": "12" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("Endereço do Segurado"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(_vm._s(_vm.policy.InsuredAddress)),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "vs-row",
          [
            _c("h5", { staticClass: "title-group" }, [
              _vm._v("Dados do Corretor"),
            ]),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-sm": "12", "vs-lg": "8" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("Razão Social do Corretor"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(_vm._s(_vm.policy.BrokerName)),
                ]),
              ]
            ),
            _c(
              "vs-col",
              {
                staticClass: "container-element pt-8",
                attrs: { "vs-sm": "12", "vs-lg": "4" },
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v("CNPJ do Corretor"),
                ]),
                _c("p", { staticClass: "value-content" }, [
                  _vm._v(_vm._s(_vm._f("maskCnpj")(_vm.policy.BrokerDocument))),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "vs-row",
          [
            _c("h5", { staticClass: "title-group my-4" }, [
              _vm._v("Dados da Apólice"),
            ]),
            _c("h5", { staticClass: "title-group mt-4" }, [
              _vm._v("Modalidades"),
            ]),
            _c(
              "vs-col",
              {
                staticClass: "px-0 py-0",
                attrs: {
                  "vs-sm": "12",
                  "vs-lg": !!_vm.policy.ComplementaryModalityId ? 6 : 12,
                },
              },
              [
                _c(
                  "vs-row",
                  { staticClass: "px-0 py-0" },
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-8",
                        attrs: {
                          "vs-sm": "12",
                          "vs-lg": !_vm.policy.ComplementaryModalityId ? 6 : 12,
                        },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Modalidade Principal"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.policy.Modality) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-8",
                        attrs: {
                          "vs-sm": "12",
                          "vs-lg": !_vm.policy.ComplementaryModalityId ? 3 : 6,
                        },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Importância Segurada Principal"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("maskCurrency")(
                                  (_vm.policy || {}).InsuredAmountValue
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-8",
                        attrs: {
                          "vs-sm": "12",
                          "vs-lg": !_vm.policy.ComplementaryModalityId ? 3 : 6,
                        },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Prêmio"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("maskCurrency")(
                                  (_vm.policy || {})
                                    .InsurancePremiumPrincipalModality
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  { staticClass: "px-0 py-0" },
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-10",
                        attrs: { "vs-sm": "12", "vs-lg": "4" },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Início da Vigência Principal"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$utils.dateToDDMMYYYY(_vm.policy.StartDate)
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-10",
                        attrs: { "vs-sm": "12", "vs-lg": "4" },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Prazo da Vigência Principal"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.policy.DeadlineDays) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-10",
                        attrs: { "vs-sm": "12", "vs-lg": "4" },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Fim da Vigência Principal"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$utils.dateToDDMMYYYY(_vm.policy.EndDate)
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            !!_vm.policy.ComplementaryModalityId
              ? _c(
                  "vs-col",
                  {
                    staticClass: "px-0 py-0",
                    attrs: { "vs-sm": "12", "vs-lg": "6" },
                  },
                  [
                    _c(
                      "vs-row",
                      { staticClass: "px-0 py-0" },
                      [
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-8",
                            attrs: { "vs-sm": "12", "vs-lg": "12" },
                          },
                          [
                            _c("p", { staticClass: "label-content label" }, [
                              _vm._v("Modalidade Secundária"),
                            ]),
                            _c("p", { staticClass: "value-content" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.policy.ComplementaryModality) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-8",
                            attrs: { "vs-sm": "6", "vs-lg": "6" },
                          },
                          [
                            _c("p", { staticClass: "label-content label" }, [
                              _vm._v("Importância Segurada Secundária"),
                            ]),
                            _c("p", { staticClass: "value-content" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("maskCurrency")(
                                      (_vm.policy || {})
                                        .ComplementaryInsuredAmountValue
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-8",
                            attrs: { "vs-sm": "6", "vs-lg": "6" },
                          },
                          [
                            _c("p", { staticClass: "label-content label" }, [
                              _vm._v("Prêmio"),
                            ]),
                            _c("p", { staticClass: "value-content" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("maskCurrency")(
                                      (_vm.policy || {})
                                        .InsurancePremiumComplementaryModality
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-10",
                            attrs: { "vs-sm": "12", "vs-lg": "4" },
                          },
                          [
                            _c("p", { staticClass: "label-content label" }, [
                              _vm._v("Início da Vigência Secundária"),
                            ]),
                            _c("p", { staticClass: "value-content" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$utils.dateToDDMMYYYY(
                                      _vm.policy.ComplementaryModalityStartDate
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-10",
                            attrs: { "vs-sm": "12", "vs-lg": "4" },
                          },
                          [
                            _c("p", { staticClass: "label-content label" }, [
                              _vm._v("Prazo da Vigência Secundária"),
                            ]),
                            _c("p", { staticClass: "value-content" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.policy.ComplementaryDeadlineDays) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-10",
                            attrs: { "vs-sm": "12", "vs-lg": "4" },
                          },
                          [
                            _c("p", { staticClass: "label-content label" }, [
                              _vm._v("Fim da Vigência Secundária"),
                            ]),
                            _c("p", { staticClass: "value-content" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$utils.dateToDDMMYYYY(
                                      _vm.policy.ComplementaryModalityEndDate
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.policy.Coverages || []).length
              ? _c(
                  "vs-row",
                  [
                    _c("h5", { staticClass: "title-group mt-4 mb-4" }, [
                      _vm._v("Coberturas Adicionais"),
                    ]),
                    _c("CoberturaColumn", {
                      attrs: {
                        "vs-sm": "12",
                        collumnWidth: 4,
                        label: "Nome da cobertura",
                        values: _vm.policy.Coverages.map((c) => c.Name),
                      },
                    }),
                    _c("CoberturaColumn", {
                      attrs: {
                        "vs-sm": "12",
                        collumnWidth: 2,
                        label: "Prêmio",
                        values: _vm.policy.Coverages.map((c) =>
                          _vm.$options.filters.maskCurrency(c.Value)
                        ),
                      },
                    }),
                    _c("CoberturaColumn", {
                      attrs: {
                        label: "Início da vigência",
                        collumnWidth: 3,
                        values: _vm.policy.Coverages.map((c) =>
                          _vm.$utils.dateToDDMMYYYY(c.StartDate)
                        ),
                      },
                    }),
                    _c("CoberturaColumn", {
                      attrs: {
                        label: "Fim da vigência",
                        collumnWidth: 3,
                        values: _vm.policy.Coverages.map((c) =>
                          _vm.$utils.dateToDDMMYYYY(c.EndDate)
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        (_vm.parcelas || []).length
          ? _c(
              "vs-row",
              [
                _c("h5", { staticClass: "title-group mt-4" }, [
                  _vm._v("Prêmio"),
                ]),
                _vm.policy.InsurancePremium
                  ? _c(
                      "vs-col",
                      {
                        staticClass: "container-element pt-8",
                        attrs: { "vs-sm": "6", "vs-lg": "3" },
                      },
                      [
                        _c("p", { staticClass: "label-content label" }, [
                          _vm._v("Prêmio Total"),
                        ]),
                        _c("p", { staticClass: "value-content" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("maskCurrency")(
                                  _vm.policy.InsurancePremium
                                )
                              ) +
                              "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "vs-col",
                  {
                    staticClass: "container-element pt-8",
                    attrs: { "vs-sm": "12", "vs-lg": "3" },
                  },
                  [
                    _c("p", { staticClass: "label-content label" }, [
                      _vm._v("Número de parcelas"),
                    ]),
                    _c("p", { staticClass: "value-content" }, [
                      _vm._v(_vm._s((_vm.parcelas || []).length)),
                    ]),
                  ]
                ),
                _c(
                  "vs-col",
                  {
                    staticClass: "container-element pt-8",
                    attrs: { "vs-sm": "12", "vs-lg": "3" },
                  },
                  [
                    _c("p", { staticClass: "label-content label" }, [
                      _vm._v("Parcelas"),
                    ]),
                    _vm._l(_vm.parcelas, function (parcela, index) {
                      return _c(
                        "p",
                        {
                          key: index,
                          staticClass: "value-content",
                          class: { "mt-4": index > 0 },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(parcela.Description) +
                              "\n        "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "vs-row",
          [
            _c("h5", { staticClass: "title-group pb-2" }, [
              _vm._v("Configuração"),
            ]),
            _c(
              "vs-col",
              { attrs: { "vs-sm": "12", "vs-lg": "4" } },
              [
                _c(
                  "vs-checkbox",
                  {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.policy.EnableToSteppin,
                      callback: function ($$v) {
                        _vm.$set(_vm.policy, "EnableToSteppin", $$v)
                      },
                      expression: "policy.EnableToSteppin",
                    },
                  },
                  [_vm._v("Retoma de objeto (Step-in)\n        ")]
                ),
              ],
              1
            ),
            _c(
              "vs-col",
              { attrs: { "vs-sm": "12", "vs-lg": "4" } },
              [
                _c(
                  "vs-checkbox",
                  {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.policy.EnableToMonitoring,
                      callback: function ($$v) {
                        _vm.$set(_vm.policy, "EnableToMonitoring", $$v)
                      },
                      expression: "policy.EnableToMonitoring",
                    },
                  },
                  [_vm._v("Monitoramento\n        ")]
                ),
              ],
              1
            ),
            _c(
              "vs-col",
              { attrs: { "vs-sm": "12", "vs-lg": "4" } },
              [
                _c(
                  "vs-checkbox",
                  {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.policy.EnableToBigRisk,
                      callback: function ($$v) {
                        _vm.$set(_vm.policy, "EnableToBigRisk", $$v)
                      },
                      expression: "policy.EnableToBigRisk",
                    },
                  },
                  [_vm._v("Grande risco\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("listar-parcelas-popup", {
          attrs: {
            parcelasModal: _vm.parcelasModal,
            propostaUniqueId: _vm.policy.ProposalUniqueId,
            autoLoad: true,
          },
          on: {
            fecharParcelasModal: function ($event) {
              _vm.parcelasModal = false
            },
            setParcelas: function ($event) {
              _vm.parcelas = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }