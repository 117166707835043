var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-col",
    {
      staticClass: "container-element mt-4",
      attrs: { "vs-sm": "12", "vs-lg": _vm.collumnWidth },
    },
    [
      _c("p", { staticClass: "label-content label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._l(_vm.values, function (value, index) {
        return _c(
          "p",
          {
            key: index,
            staticClass: "value-content",
            class: { "mt-4": index > 0 },
          },
          [_vm._v("\n    " + _vm._s(value) + "\n  ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }