<template>
    <vs-col class="container-element mt-4" vs-sm="12" :vs-lg="collumnWidth">
      <p class="label-content label">{{ label }}</p>
      <p
        v-for="(value, index) in values"
        :key="index"
        class="value-content"
        :class="{ 'mt-4': index > 0 }"
      >
        {{ value }}
      </p>
    </vs-col>
</template>

<script>
export default {
  props: {
    label: String,
    values: Array,
    collumnWidth: Number,
  }
}
</script>
